import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Separator } from 'app/components/Common/Separator'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { chunk } from 'lodash'
import React, { memo } from 'react'
import { Gallery as PhotoSwipe } from 'react-photoswipe-gallery'

import { Image, Props as ImageProps } from './Image'

export interface Props {
  cta?: ButtonProps[]
  description?: string
  languageCode: string
  images: ImageProps[]
  separator?: boolean
  title?: string
}

export const Gallery = memo(function Gallery({
  cta,
  description,
  images,
  languageCode,
  separator = true,
  title,
}: Props) {
  if (images.length < 1) {
    return null
  }

  const chunkedItems = chunk(images, 4)

  return (
    <Container>
      {separator ? <Separator /> : undefined}
      {title ? (
        <Head>
          <Text>
            <Title>{title}</Title>
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
            {cta
              ? cta.map((item, index) => (
                  <Button key={index} {...item} variant="simple" />
                ))
              : null}
          </Text>
        </Head>
      ) : (
        <Label>{useVocabularyData('gallery', languageCode)}</Label>
      )}
      <Wrapper>
        <PhotoSwipe
          options={{
            barsSize: {
              top: 0,
              bottom: 0,
            },
            closeElClasses: '',
            closeOnScroll: false,
            closeOnVerticalDrag: false,
            pinchToClose: false,
            fullscreenEl: false,
            zoomEl: false,
            shareEl: false,
            preloaderEl: false,
            mouseUsed: true,
            tapToToggleControls: false,
            history: false,
            showHideOpacity: true,
            maxSpreadZoom: 1,
          }}
        >
          {chunkedItems.map((images, index) => (
            <ImagesWrap key={index} row wrap>
              {images.map((item, index) => (
                <Image key={index} {...item} />
              ))}
            </ImagesWrap>
          ))}
        </PhotoSwipe>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 6rem auto 9.75rem;
  padding: 0 1.5rem;
  text-align: center;

  @media (max-width: 1023px) {
    margin: 3.75rem auto 7.5rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 1.125rem;
  margin-bottom: 2.25rem;
  text-transform: uppercase;
`

const ImagesWrap = styled(FlexBox)`
  width: 100%;
  margin-top: 1.5rem;
  position: relative;
  &:nth-of-type(even) {
    > div {
      &:nth-of-type(1) {
        order: 2;
      }
      &:nth-of-type(2) {
        order: 3;
        margin-right: 0;
      }
      &:nth-of-type(3) {
        margin-right: 1.5rem;
      }
      &:nth-of-type(4) {
        right: auto;
        left: 0;
      }
    }
  }
`

const Head = styled.div`
  margin-top: -8vh;

  @media (max-width: 767px) {
    margin-top: 0;
  }
`

const Text = styled.div`
  max-width: 34.375rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: auto;
  padding: 0 7.5rem 5.875rem;
  position: relative;
  z-index: 2;
  transform: translateY(8vh);

  @media (max-width: 1023px) {
    padding: 0 6rem 3.75rem;
  }

  @media (max-width: 767px) {
    margin-bottom: 3.75rem;
    padding: 0;
    transform: none;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3.375rem;
  margin-bottom: 3rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;
`

const Wrapper = styled.div`
  max-width: 82.5rem;
  margin: auto;

  @media (max-width: 767px) {
    .pswp__button {
      &.pswp__button--arrow--left,
      &.pswp__button--arrow--right {
        display: none;
      }
    }
  }

  /* OVERRIDE PHOTOSWIPE STYLE */
  .pswp__bg {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
  .pswp__img--placeholder--blank {
    background: none;
  }
  .pswp--touch {
    .pswp__button--arrow--left,
    .pswp__button--arrow--right {
      visibility: visible;
    }
  }
  .pswp__caption {
    display: flex;
    min-height: 0;
    background: none;
    .pswp__caption__center {
      max-width: none;
      background: ${({ theme }) => theme.colors.variants.neutralLight3};
      color: ${({ theme }) => theme.colors.variants.neutralDark4};
      font-family: ${({ theme }) => theme.fontFamily.paragraph};
      font-size: 0.8125rem;
      padding: 0.375rem 1.5rem;
      text-align: center;
    }
  }
  .pswp__top-bar {
    height: auto;
    background: none;
  }
  .pswp__button {
    width: 100px;
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 1;
    &.pswp__button--arrow--left,
    &.pswp__button--arrow--right {
      margin-top: 0;
      transform: translateY(-50%);
      &:before {
        display: none;
        background-color: transparent;
      }
    }
    &.pswp__button--close {
      width: 40px;
      height: 40px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='1' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%0A%3E%3Cline x1='18' y1='6' x2='6' y2='18' /%3E%3Cline x1='6' y1='6' x2='18' y2='18' /%3E%3C/svg%3E%0A");
      margin: 24px;
    }
    &.pswp__button--arrow--left {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='0.5' stroke-linecap='round' stroke-linejoin='round'%0A%3E%3Cpolyline points='15 18 9 12 15 6' /%3E%3C/svg%3E%0A");
    }
    &.pswp__button--arrow--right {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='black' stroke-width='0.5' stroke-linecap='round' stroke-linejoin='round'%0A%3E%3Cpolyline points='9 18 15 12 9 6' /%3E%3C/svg%3E%0A");
    }
  }
  .pswp__counter {
    height: 40px;
    line-height: 40px;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    color: ${({ theme }) => theme.colors.variants.neutralDark4};
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    font-size: 0.75rem;
    opacity: 1;
    margin: 24px;
  }

  @media (-webkit-min-device-pixel-ratio: 1.1),
    (-webkit-min-device-pixel-ratio: 1.09375),
    (min-resolution: 1.1dppx),
    (min-resolution: 105dpi) {
    .pswp--svg .pswp__button--close:before,
    .pswp--svg .pswp__button--arrow--left:before,
    .pswp--svg .pswp__button--arrow--right:before {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQgAAABYCAQAAACjBqE3AAAB6klEQVR4Ae3bsWpUQRTG8YkkanwCa7GzVotsI/gEgk9h4Vu4ySLYmMYgbJrc3lrwZbJwC0FMt4j7F6Y4oIZrsXtgxvx/1c0ufEX4cnbmLCmSJEmSJEmSJEmSJP3XCBPvbJU+8doWmDFwyZpLBmYlNJebz0KwzykwsuSYJSNwykEJreV2BaBMaLIQZ2xYcFgqDlmw4ayE/FwL0dDk4Qh4W37DAjgqIT+3HRbigjH+iikVdxgZStgyN0Su2sXIeTwTT+esdpcbIlfNAuZ/TxresG4zV8kYWSZNiKUTokMMSWeIwTNEn4fK2TW3gRNgVkJLuVksROA9G+bEvoATNlBCa7nZXEwdxEZxzpKRKFh+bsv8LmPFmhX1OwfIz81jIRJQ5eeqG9B+riRJkiRJkiRJkiRJkiRJkiRJUkvA/8RQoEpKlJWINFkJ62AlrEP/mNBibnv2yz/A3t7Uq3LcpoxP8COjC1T5vxoAD5VdoEqdDrd5QuW1swtUSaueh3zkiuBiqgtA2OlkeMcP/uDqugsJdbjHF65VdPMKwS0+WQc/MgKvrIOHysB9vgPwk8+85hmPbnQdvHZyDMAFD7L3EOpgMcVdvnHFS0/vlatrXvCVx0U9gt3fxvnA0/hB4nmRJEmSJEmSJEmSJGmHfgFLaDPoMu5xWwAAAABJRU5ErkJggg==) !important;
    }
  }
`
