import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import styled from '@emotion/styled'
import React, { memo } from 'react'
import { Item } from 'react-photoswipe-gallery'

export interface Props {
  original: string
  thumbnail?: string
  title?: string
  width: string
  height: string
}

export const Image = memo(function Image({
  original,
  thumbnail,
  title,
  width,
  height,
}: Props) {
  return (
    <Container>
      <Item
        original={original}
        thumbnail={thumbnail}
        title={title}
        width={width}
        height={height}
      >
        {({ ref, open }) => (
          <>
            <Inner
              ref={ref as React.RefObject<HTMLDivElement>}
              onClick={open}
              style={{
                backgroundImage: `url(${thumbnail})`,
              }}
            />
          </>
        )}
      </Item>
    </Container>
  )
})

const Container = styled.div`
  width: calc(33.333% - 1rem);
  cursor: pointer;
  margin-right: 1.5rem;
  padding-bottom: calc(44% - 1.5rem);
  position: relative;
  &:nth-of-type(3),
  &:nth-of-type(4) {
    margin-right: 0;
    padding-bottom: calc(22% - 1.5rem);
  }
  &:nth-of-type(4) {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  @media (max-width: 767px) {
    width: calc(33.333% - 0.5rem);
    margin-right: 0.75rem;
    padding-bottom: calc(44% - 0.75rem);
    &:nth-of-type(3),
    &:nth-of-type(4) {
      padding-bottom: calc(22% - 0.75rem);
    }
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
`
